<template>
  <div class="category">
      <el-tree :data="menus" :props="defaultProps" node-key="catId" ref="menuTree" @node-click="nodeClick"></el-tree>
  </div>
</template>

<script>
import {getCategoryListWithTree} from "@/services/product";

export default {
  name: 'category',
  data() {
    return {
      expandedKey:[],
      menus: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  methods: {
     // 查询树形分类列表
    async loadCategoryListTree() {
      const {data} = await getCategoryListWithTree()
      if (200 === data.code) {
        this.menus = data.data
      } else {
        this.$message.error('查询树形分类列表失败')
      }
    },
    nodeClick(data,node,component) {
      // 向父组件发送事件
      this.$emit('tree-node-click',data,node,component)
    },

  },
  created() {
    this.loadCategoryListTree()
  },
  filters: {}
}
</script>

<style lang="scss" scoped>

</style>
