<template>
  <div class="colour">
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <category @tree-node-click="treeNodeClick"></category>
        </el-col>
        <el-col :span="18">
          <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="small" >
            <el-form-item label="颜色名称：" prop="colourName">
              <el-input v-model="form.colourName" clearable size="small"></el-input>
            </el-form-item>
            <el-form-item label="分类编码：" prop="catId">
              <el-input v-model="form.catId" size="small" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="分类名称：" prop="categoryName">
              <el-input v-model="form.categoryName" size="small" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="query()" size="mini" :disabled="isLoading">查询</el-button>
            </el-form-item>
            <el-form-item class="btn-right">
              <el-button @click="add()" size="mini" type="success">新增</el-button>
            </el-form-item>
          </el-form>
          <div>
            <el-table
              :data="tableData"
              border
              v-loading="isLoading"
              style="width: 100%" size="small">
              <el-table-column type="index" label="编号" width="80"></el-table-column>
              <el-table-column prop="colourId" label="颜色编码"></el-table-column>
              <el-table-column prop="colourName" label="颜色名称"></el-table-column>
              <el-table-column prop="catId" label="分类编码"></el-table-column>
              <el-table-column label="操作"  fixed="right">
                <template slot-scope="{row}">
                  <el-button @click="edit(row)" type="warning" size="mini" >修改</el-button>
                  <el-button @click="del(row)" type="danger" size="mini" >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--      分页组件结构-->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.currentPage"
            :page-sizes="[10,50,100,200]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataTotal"
            :disabled="isLoading"
          >
          </el-pagination>
        </el-col>
      </el-row>
      <div v-if="dialogVisible">
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="30%"
          @close="onCancel">
          <div >
            <div>
              <el-form :model="colour" label-position="left" label-width="80px" size="small">
                <el-form-item label="颜色名称">
                  <el-input v-model="colour.colourName"></el-input>
                </el-form-item>
                <el-form-item label="分类编码">
                  <el-cascader
                    v-model="colour.categoryIds"
                    :options="menus" :props="props" clearable></el-cascader>
                </el-form-item>

              </el-form>
            </div>
            <div slot="footer">
              <el-button @click="onCancel">取 消</el-button>
              <el-button type="primary" @click="saveColour">确 定</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
import {getCategoryListWithTree} from "@/services/product";
import Category from "../components/category";
import {findColourByCatId,saveColour,editColour,getColourByColourId,delColourByColourId} from "@/services/product";
export default {
  name: 'colour',
  components:{Category},
  data() {
    return {
      props:{
        value:'catId',
        label:'name',
        children:'children'
      },
      menus: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      deptSortOutList: [],
      // 数据总数
      dataTotal: 0,
      tableData: [],
      isLoading : false,
      dialogVisible:false,
      title:'',
      colour:{},
      dialogType:'',
    };
  },
  methods: {
    // 新增或修改确认按钮
    async saveColour() {
      this.colour.catId=this.colour.categoryIds[this.colour.categoryIds.length-1]
      if (this.dialogType=='add') {
        const {data} = await saveColour(this.colour);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadColourByCatId()
          this.colour = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
      if (this.dialogType=='edit') {
        const {data} = await editColour(this.colour);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadColourByCatId()
          this.colour = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
    },
    onCancel () {
      this.dialogVisible = false
      this.colour = {}
    },

    // 新增
    add(){
      this.dialogType = 'add'
      this.title = '新增'
      this.dialogVisible = true
    },
    // 修改
    async edit(row){
      this.dialogVisible=true
      this.title='编辑'
      this.dialogType='edit'
      const {data} = await getColourByColourId(row.colourId)
      if (data.code ===200) {
        this.colour = data.data
      } else {
        this.$message.error('获取分类信息失败')
      }
    },

    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadColourByCatId()
    },
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadColourByCatId()
    },
    //感知树节点被点击
    treeNodeClick(data,node,component){
      this.form.currentPage = 1
      this.form.catId = data.catId
      this.form.categoryName = data.name
      if (node.childNodes.length===0) {
        // 调用查询功能
        this.loadColourByCatId()
      }
    },


    // 查询按钮
    query() {
      this.loadColourByCatId()
    },

    // 分页查询颜色信息
    async loadColourByCatId() {
      // 开始加载数据
      this.isLoading = true
      const {data} = await findColourByCatId(this.form)
      if (data.code == 200) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },
    // 查询树形分类列表
    async loadCategoryListTree() {
      const {data} = await getCategoryListWithTree()
      if (200 === data.code) {
        this.menus = data.data
      } else {
        this.$message.error('查询树形分类列表失败')
      }
    },
    // 删除
    async del(row){
      try {
        await this.showConfirmationDialog(row);
        // 用户点击了确定按钮的逻辑
        await this.remove(row);
      } catch {
        this.$message.info('取消操作')
        // 用户点击了取消按钮的逻辑
        // 可以不做任何操作，或者执行取消操作
      }
    },
    // 删除调用后台接口
    async remove(row) {
      let colourId = row.colourId
      const {data} =await delColourByColourId(colourId)
      if (200 === data.code) {
        this.$message.success('操作成功')
        await this.loadColourByCatId()
      } else {
        this.$message.error('操作失败')
      }
    },
    // 删除提示弹框
    showConfirmationDialog(row) {
      return new Promise((resolve, reject) => {
        this.$confirm(`是否删除【${row.colourName}】？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          resolve(); // 用户点击了确定按钮，将Promise解析为成功状态
        }).catch(() => {
          reject(); // 用户点击了取消按钮，将Promise解析为失败状态
        });
      });
    },
  },
  created() {
    this.loadCategoryListTree()
  },
  filters: {}
}
</script>

<style lang="scss" scoped>
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
